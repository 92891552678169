import { useEffect, useId, useState } from "react";
import { useLocation, useParams, useSearchParams, Outlet, useNavigate, useOutletContext } from "react-router-dom";
//import { useGetUsers } from "../functions/useGetUsers";

import Swal from 'sweetalert2'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



export default function FormTerm() {

  const location = useLocation();
  const navigate = useNavigate();
  
  const outletData = useOutletContext();
  

  let urlParams = useParams();
  const term = location.state;


  const formID = useId();

  let token = btoa("adminbg" + ":" + "Fj55t5rhKKg%%M3bQ5jOmf");
  const headers = {
  Authorization: "Basic " + token,
  //'Content-Type': 'application/json'
  };

  const handleDelete = async (e) =>{
      e.preventDefault();

      
      const term_to_delete = new FormData(e.target);



      Swal.fire({
          title: 'Tem certeza?',
          text: "Este caminho não tem volta.",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Deletar ' + term_to_delete.get("term_name"),
        }).then((result) => {
          if (result.isConfirmed) {


              fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/delete_term", {
                  method: "POST",
                  headers: headers,
                  //body: JSON.stringify( user_data ),
                  body: term_to_delete,
                  })
                  .then((response) => {
                      console.log(response);
                      if(response.ok){
                          return response.json().then((data) => {
                              console.log(data);
                              Swal.fire({
                                  title: 'Sucesso',
                                  text: data.message,
                                  icon: 'success',
                                  confirmButtonText: 'Ok'
                              }).then( () => {
                                  //navigate('/usuarios', {replace: true});
                                  //navigate('/empresas');
                                  navigate('../');
                                  navigate(0);
                              })
                          })
                      }else{
                          return response.json().then((err) => {
                              return Promise.reject(err);
                          })            
                      }        
                  })      
                  .catch((err) => {
                      console.log("Houve um Erro na atualização.");
                      console.log(err);
                      Swal.fire({
                          title: 'Erro',
                          text: err.message,
                          icon: 'error',
                          confirmButtonText: 'Ok'
                      })
                  });
          }
        })



  };


  async function criaWhatsAppGroup(wa_group_name) {
    console.log('criaWhatsAppGroup', wa_group_name);
    toast.info('Criando Grupo ' + wa_group_name);

    let headers = {
        "Content-Type": "application/json",
        'apikey': 'cUL1yTaK5rjgau5a5ws16CqKa4LhAl74'
    };
    let wa_group_data = {
        "subject": wa_group_name,
        "participants": [
            "554699239694", // erick normal
            "554691027754", // erick corporativo
            "554691373480", //	# Geovane
            "554691207321", //	# Julio
            "554699195055", //	# Matheus
            "554198270100", //	# Anderson BG
            "554691386536", //	#Business Group
            "554691042208", //	# Carlos Secco
            "554699020690", //	# Clayton BG
            "554699114070", //	# Cristiane
            "554691094145", //	# Emanuel Antunes
            "554599259771", //	# Gabriel Unser
            "554691157294", //	#John Berticelli
            "5518997198811", //	# Lara
            "554688044668", //	# Mônica
            "554699016003", //	#Maurício Cielo
            "554699059085", //	#Mariana         
            "554699736858", //  #Eve   
        ],
    };

    try {
        const response = await fetch("https://evoapi.catalystbg.com.br/group/create/carlos", {
            method: "POST",
            headers: headers,
            body: JSON.stringify(wa_group_data),
        });

        console.log('Response:', response);

        if (response.ok) {
            const data = await response.json();
            console.log("Grupo criado com sucesso");
            console.log('Data:', data);
            toast.success('Sucesso! Grupo criado: "' + data.id + '"');
            return data.id; // Retorna o ID do grupo
        } else {
            const err = await response.json();
            console.log('Erro na resposta:', err);
            toast.error('Erro: ' + err.response.message);
            throw new Error(err.response.message); // Lança erro para ser capturado no catch
        }
    } catch (err) {
        console.log("Houve um erro na requisição:", err);
        toast.error('Erro na requisição: ' + err.message);
        throw err; // Propaga o erro para o chamador, se necessário
    }
  }



  const handleSubmit = async (e) => {
    e.preventDefault();

    const user_data = new FormData(e.target);


    if(user_data.get('term_name') == ''){
      await Swal.fire({
        title: 'Erro',
        text: "O nome da Empresa não pode estar em branco.",
        icon: 'error',
        confirmButtonText: 'Ok'
      });

      return;
    }



    //Criar um grupo no WhatsApp, caso o campo Grupo esteja em branco.
    
    if(!user_data.get('client_wa_group')){
      console.log('handleSubmit', "o Campo client_wa_group está em branco. Criar um grupo no WhatsApp.");

      let wa_group_name = "BG + " + user_data.get('term_name');

      let wa_group_id = await criaWhatsAppGroup(wa_group_name);

      user_data.set('client_wa_group', wa_group_id);
      console.log('handleSubmit', "Grupo criado com sucesso. ID: " + wa_group_id);
      
      //
      //Retorno do endpoint
      /*
      {
          "id": "120363400906044256@g.us",
          "subject": "Teste group 2",
          "subjectOwner": "554691042208@s.whatsapp.net",
          "subjectTime": 1743464890,
          "size": 2,
          "creation": 1743464890,
          "owner": "554691042208@s.whatsapp.net",
          "restrict": false,
          "announce": false,
          "isCommunity": false,
          "isCommunityAnnounce": false,
          "joinApprovalMode": false,
          "memberAddMode": false,
          "participants": [
              {
                  "id": "554691042208@s.whatsapp.net",
                  "admin": "superadmin"
              },
              {
                  "id": "554699239694@s.whatsapp.net",
                  "admin": null
              }
          ]
      }
      */

    }




    try {
      const response = await fetch("https://www.catalystbg.com.br/wp-json/catalystbg/v1/new_term", {
          method: "POST",
          headers: headers,
          body: user_data, // Já é FormData, não precisa de JSON.stringify
      });

      console.log('Response:', response);

      if (response.ok) {
          const data = await response.json();
          console.log("A solicitação foi feita.");
          console.log('Data:', data);

          await Swal.fire({
              title: 'Sucesso',
              text: data.message,
              icon: 'success',
              confirmButtonText: 'Ok'
          });

          // Navegação após o Swal.fire
          navigate('./');
          navigate(0); // Recarrega a página atual

          e.target.reset(); // Reseta o formulário
      } else {
          const err = await response.json();
          console.log('Erro na resposta:', err);
          throw new Error(err.message); // Lança o erro para o catch
      }
    } catch (err) {
        console.log("Houve um erro na atualização:", err);

        await Swal.fire({
            title: 'Erro',
            text: err.message,
            icon: 'error',
            confirmButtonText: 'Ok'
        });
    }


    console.log("Form submitted.");
  };


  
  const [formData, setFormData] = useState({});

  useEffect(() => {
    
    setFormData(term || null);

    console.log('term',term);
    console.log('outletData',outletData);
    
    

  }, [term]);


  return (
    <>

        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">

            {/* <h1 className="h2">{urlParams.userName ? urlParams.userName : 'Adicionar usuário'}</h1> */}
            <h1 className="h2">{term?.name ? term.name : 'Adicionar novo'}</h1>
            
            <div className="btn-toolbar mb-2 mb-md-0">
                <form onSubmit={handleDelete} >
                    <input type="hidden" name="term_id" defaultValue={term?.term_id} />
                    <input type="hidden" name="term_name" defaultValue={term?.name} />
                    <input type="hidden" name="taxonomy_name" defaultValue={term?.taxonomy} />
                    <button className="btn btn-sm btn-outline-danger"> Deletar </button>
                </form>
            </div>
        </div>


            <div className="card mb-5 bg-body">


              <div className="card-body fs-6 py-15 px-10 text-gray-700">

               

              <Outlet />


                <form onSubmit={handleSubmit}>

                <input
                    type="hidden"
                    //defaultValue={term?.term_id}
                    value={formData?.term_id || ''}
                    onChange={e => setFormData({
                      ...formData,
                      term_id: e.target.value
                    })}
                    name="term_id"
                />
                <input
                    type="hidden"
                    defaultValue={term ? term.taxonomy : outletData}
                    name="taxonomy_name"
                />
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-term_name"}
                        >
                          Nome
                        </label>
                        <input
                          type="text"
                          //defaultValue={term?.name}
                          value={formData?.name || ''}
                          onChange={e => setFormData({
                            ...formData,
                            name: e.target.value
                          })}
                          name="term_name"
                          id={formID + "-term_name"}
                          className="form-control"
                          placeholder=""
                        />
                      </div>

                      { outletData == 'client' && ( 
                      // {console.log('outletData = ', outletData) }
                      // {console.log('term = location.state = {term}', term) }
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-client_cidade"}
                        >
                          Cidade
                        </label>
                        <input
                          type="text"
                          value={formData?.cidade || ''}
                          onChange={e => setFormData({
                            ...formData,
                            cidade: e.target.value
                          })}
                          name="client_cidade"
                          id={formID + "-client_cidade"}
                          className="form-control"
                          placeholder=""
                        />
                      </div>

                      )}

                      { outletData == 'client' && ( 
                      // {console.log('outletData = ', outletData) }
                      // {console.log('term = location.state = {term}', term) }
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-client_wa_group"}
                        >
                          Grupo WhatsApp (Deixe em branco para criar um novo)
                        </label>
                        <input
                          type="text"
                          value={formData?.wa_group || ''}
                          onChange={e => setFormData({
                            ...formData,
                            wa_group: e.target.value
                          })}
                          name="client_wa_group"
                          id={formID + "-client_wa_group"}
                          className="form-control"
                          placeholder=""
                        />
                      </div>

                      )}

                      { outletData == 'client' && ( 
                      <div className="mb-3">
                        <label
                          className="form-label"
                          htmlFor={formID + "-client_situacao"}
                        >
                          Situação
                        </label>
                        

                        <select className="form-select"
                          value={formData?.situacao || ''}
                          onChange={e => setFormData({
                            ...formData,
                            situacao: e.target.value
                          })}
                          name="client_situacao"
                          id={formID + "-client_situacao"}
                          >
                          <option value=""></option>  
                          <option value="ativa">Ativa</option>
                          <option value="inativa">Inativa</option>                          
                        </select>
                        
                      </div>

                      )}

                    </div>
                    
                    
                  </div>

                  

                  <button type="submit" className="btn btn-primary"> Salvar </button>
                </form>

                {/* <div className="response bg-secondary m-10 p-10">
                  {responseMsg}
                </div> */}
              </div>
            </div>
          
        



        
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        // transition={Bounce}
    />



    </>
  );
}
